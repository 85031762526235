<template>
  <section class="waiting__content">
    <ProgressCircular :value="value">
      {{ value }}%
    </ProgressCircular>
    <p
      class="waiting__caption"
      :class="{
        invisible: value > 99,
      }"
    >
      Не закрывайте вкладку и не уходите с нее.
    </p>
  </section>
</template>

<style lang="scss">
.waiting__caption {
  margin: 3rem 0;
}
</style>

<script>
import ProgressCircular from "./progress-circular/progress-circular.vue";

export default {
  name: "Waiting",
  components: {
    ProgressCircular,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
};
</script>
