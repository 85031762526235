<template>
  <div
    class="progress-circular"
    role="progressbar"
    aria-valuemin="0"
    aria-valuemax="100"
    :aria-valuenow="value"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      viewport="0 0 200 200"
      style="transform: rotate(270deg);"
    >
      <circle
        class="progress-circular__underlay"
        fill="transparent"
        cx="100"
        cy="100"
        :r="radius"
        stroke-width="8"
        stroke-dashoffset="0"
      />
      <circle
        class="progress-circular__overlay"
        fill="transparent"
        cx="100"
        cy="100"
        :r="radius"
        stroke-width="8"
        :stroke-dasharray="offsetNull"
        :stroke-dashoffset="offset"
      />
      <ellipse
        v-if="isDone"
        class="progress-circular__done"
        cx="100"
        cy="100"
        rx="100"
        ry="100"
      />
      <path
        v-if="isDone"
        d="M66 95.1923L95.8795 125L146 75"
        stroke="white"
        fill="transparent"
        transform="rotate(90 100 100)"
        stroke-width="8"
      />
    </svg>
    <div class="progress-circular__info">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressCircular",
  props: {
    value: {
      type: [Number, String],
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      radius: 96,
      offsetNull: 603.186,
    };
  },
  computed: {
    offset() {
      const radius = this.radius;
      const areaOfCircle = Math.PI * (radius * 2);
      let val = Number(this.value);

      if (val < 0) {
        val = 0;
      }

      if (val > 100 || isNaN(val)) {
        val = 100;
      }

      return ((100 - val) / 100) * areaOfCircle;
    },
    isDone: (vm) => Number(vm.value) > 99,
  },
};
</script>

<style lang="scss" src="./progress-circular.scss"></style>
